<template>
  <v-container fluid>
    <dns-table :data="data" :loading="loading" />
  </v-container>
</template>

<script>
import { getDns } from "@/api/system/dns";
import dnsTable from "@/views/system/dns/table";

import { Errors } from "@/utils/error";

export default {
  components: {
    dnsTable,
  },
  data() {
    return {
      data: {
        allowedparts: {},
      },
      loading: true,
    };
  },
  computed: {
    changeInAddForm: {
      get() {
        return this.$store.state.settings.changeInAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "changeInAddForm",
          value: val,
        });
      },
    },
  },
  watch: {
    changeInAddForm: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue === "systemDns") {
          this.loadData(this.page, this.size);
        }
        this.changeInAddForm = "";
      },
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      getDns()
        .then((response) => {
          this.loading = false;
          this.data = response.data;
        })
        .catch((e) => {
          Errors.show(e);
          this.loading = false;
        });
    },
  },
};
</script>
